import SimpleCenteredHero from '../components/Landing/SimpleCenteredHero';
import PrimaryFeatures from '../components/Landing/PrimaryFeatures';
import SecondaryFeatures from '../components/Landing/SecondaryFeatures';
import CallToAction from '../components/Landing/CallToAction.jsx';
import Pricing from '../components/Landing/Pricing.jsx';
import Footer from '../components/Infrastructure/Footer.jsx';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function Landing() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className='overflow-auto'>
      <SimpleCenteredHero />
      <PrimaryFeatures />
      <SecondaryFeatures />
      <Pricing/>
      <Footer />
      {/* <CallToAction/> */}
    </div>
  )
}

import React, { Suspense, lazy } from "react";
import { useQuery } from "@tanstack/react-query";
import { useCampaignContext } from "../contexts/CampaignContext";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Atoms/Loading";

import LightTheme from "../themes/LightTheme";
import DarkTheme from "../themes/DarkTheme";

import GothicTheme from "../themes/GothicTheme";
import GothicDarkTheme from "../themes/GothicDarkTheme";
import ApocalypseTheme from "../themes/ApocalypseTheme";
import PlainsTheme from "../themes/PlainsTheme";
import MountainsTheme from "../themes/MountainsTheme";
import DesertTheme from "../themes/DesertTheme";
import NauticalTheme from "../themes/NauticalTheme";

const TYPE_OF_THEME = Object.freeze({
  LIGHT_MODE: "light",
  DARK_MODE: "dark",
});

const ThemeSelector = ({ children }) => { 
  const { isAuthenticated } = useAuth0();
  const campaignContext = useCampaignContext();
  const { getThemes } = campaignContext;    
  const CHOSEN_THEME = localStorage.getItem("theme") || TYPE_OF_THEME.DARK_MODE;

  const ThemesQuery = useQuery({ queryKey: ["Themes"], queryFn: () => getThemes() });

  if (ThemesQuery.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Suspense fallback={<></>}>
        <LightTheme />
        <DarkTheme />

        {isAuthenticated && ThemesQuery.data?.find((x) => x.Id == 1) && <GothicTheme />}
        {isAuthenticated && ThemesQuery.data?.find((x) => x.Id == 2) && <ApocalypseTheme />}
        {isAuthenticated && ThemesQuery.data?.find((x) => x.Id == 3) && <PlainsTheme />}
        {isAuthenticated && ThemesQuery.data?.find((x) => x.Id == 4) && <MountainsTheme />}
        {isAuthenticated && ThemesQuery.data?.find((x) => x.Id == 5) && <NauticalTheme />}
        {isAuthenticated && ThemesQuery.data?.find((x) => x.Id == 6) && <DesertTheme />}
        {isAuthenticated && ThemesQuery.data?.find((x) => x.Id == 7) && <GothicDarkTheme />}
      </Suspense>
      {children}
    </>
  );
};

export default ThemeSelector;
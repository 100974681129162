import React, { Fragment, useEffect, useState, Suspense, lazy } from 'react';
import { useQuery } from "@tanstack/react-query";
import { useCampaignContext } from "../contexts/CampaignContext";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from 'react-ga4';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react';
import Loading from '../components/Atoms/Loading';
import Button from '../components/Atoms/Button';
import Modal from '../components/Molecules/Modal';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import EmptyStateCampaign from '../components/Molecules/EmptyStateCampaign';
import { PERMISSIONS } from '../utilities/Constants';

const PageHeader = lazy(() => import('../components/Infrastructure/PageHeader'));
const SessionList = lazy(() => import("../components/Organisms/SessionList"));
const Legend = lazy(() => import("../components/Organisms/Legend"));
const QuestList = lazy(() => import("../components/Organisms/QuestList"));
const AdvancedSearch = lazy(() => import("../components/Organisms/AdvancedSearch"));
const TagManager = lazy(() => import("../components/Organisms/TagManager"));

export default function Campaign() {
    const campaignContext = useCampaignContext();
    const { currentCampaignId, setCurrentCampaignId, getCampaign, getKeys, getNoteTypes, getKeyWords, addSession, getSessions } = campaignContext;
    const { id } = useParams();
    const campaignId = parseInt(id);
    
    let navigate = useNavigate();
    
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [currentCampaign, setCurrentCampaign] = useState(null);
    const [activeTab, setActiveTab] = useState("Sessions");
    
    const {isLoading : campaignIsLoading, data : campaignData, error: campaignError} = useQuery({ queryKey: ["Campaign"], queryFn: () => getCampaign(campaignId)});
    const SessionsQuery = useQuery({ queryKey: ["Sessions", campaignId ?? 0], queryFn: () => getSessions(campaignId)});
    const KeysQuery = useQuery({ queryKey: ["Keys"], queryFn: () => getKeys(campaignId)});
    const NoteTypesQuery = useQuery({ queryKey: ["NoteTypes", campaignId ?? 0], queryFn: () => getNoteTypes(campaignId)});
    const KeyWordsQuery = useQuery({ queryKey: ["Keywords"], queryFn: () => getKeyWords(campaignId)});    

    
    const ContentMap = {
        Sessions: 
            <>
                {SessionsQuery.data?.length == 0 && <EmptyStateCampaign onClick={(e) =>
                    {
                        addSession(e, campaignId).then(response => 
                        {
                            let SessionData = response.data;
                            navigate(`/campaigns/${campaignId}/session/${SessionData[0][0].Id}`)
                        });
                    }
                } />}
                {SessionsQuery.data?.length > 0 && <SessionList Sessions={SessionsQuery.data || []} Keys={KeysQuery.data || []} />}
            </>,
        Legend: <Legend NoteTypesQuery={NoteTypesQuery} KeysQuery={KeysQuery} CampaignId={campaignId} />,
        QuestList: <QuestList KeysQuery={KeysQuery} NoteTypesQuery={NoteTypesQuery} KeyWordsQuery={KeyWordsQuery} CampaignData={currentCampaign} CampaignId={campaignId} />,
        Search: <AdvancedSearch KeysQuery={KeysQuery} NoteTypesQuery={NoteTypesQuery} KeyWordsQuery={KeyWordsQuery} />,
        TagManager: <TagManager KeysQuery={KeysQuery} CampaignId={campaignId} />
    }

    useEffect(() =>
    {
        if (!campaignIsLoading && !campaignError && campaignData)
        {
            setCurrentCampaign(campaignData[0]);
        }
    }, [campaignIsLoading, campaignError, campaignData])

    useEffect(() =>
    {
        if (currentCampaignId === null)
        {
            if (campaignId != undefined)
            {
                setCurrentCampaignId(campaignId);
            }
        }
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);
      

    const pageHeaderActions = [
        {
            name: 'New Session', icon: 'Plus', onClick: (e) =>
            {
                addSession(e, campaignId).then(response => 
                {
                    let SessionData = response.data;
                    navigate(`/campaigns/${campaignId}/session/${SessionData[0][0].Id}`)
                });
            }, group: false, visibleTo: PERMISSIONS.Editor
        },        
        { name: 'Invite', icon: 'UserPlus', onClick: () => { setIsInviteDialogOpen(true)}, group: true, visibleTo: PERMISSIONS.Editor },
        { name: 'Settings', icon: 'Cog', onClick: () => { navigate(`/campaigns/${campaignId}/edit`) }, group: true, visibleTo: PERMISSIONS.Editor },
        //{ name: 'Delete', icon: 'Trash', onClick: () => { setIsDeleteDialogOpen(true) }, group: true },
    ];

    const subheaderInfo = [
        // {name: 'Created on', data: moment(currentCampaign?.CreatedDate).add(1, 'd').format("MMMM Do, YYYY"), icon: 'Calendar'},
        {name: 'Began', data: moment(currentCampaign?.StartDate).add(1, 'd').format("MMMM Do, YYYY"), icon: 'Calendar'}
    ];

    const tabs = [
        {name: "Sessions", value: "Sessions", current: activeTab == "Sessions", onClick: () => setActiveTab("Sessions")},
        {name: "Legend", value: "Legend", current: activeTab == "Legend", onClick: () => setActiveTab("Legend")},
        {name: "Check List", value: "QuestList", current: activeTab == "QuestList", onClick: () => setActiveTab("QuestList")},
        {name: "Advanced Search", value: "Search", current: activeTab == "Search", onClick: () => setActiveTab("Search"), mobileDisabled: true},
        {name: "Tag Manager", value: "TagManager", current: activeTab == "TagManager", onClick: () => setActiveTab("TagManager"), mobileDisabled: true}
    ];

    let InviteLink = `https://inkless.app/campaigns/${campaignId}/join/${currentCampaign?.InviteCode}`;

    return (
        <>
            <Suspense fallback={<Loading />}><PageHeader pageTitle={currentCampaign?.Name} actions={pageHeaderActions} subheader={subheaderInfo} tabs={tabs} tabChange={setActiveTab} campaignId={campaignId} /></Suspense>
            <div id="CampaignContainer" className="flex flex-row gap-2 sm:px-2 md:px-4 py-4 justify-center grid-cols-2 overflow-hidden themeBackgroundImage">
                <Suspense fallback={<Loading />}>{ContentMap[activeTab]}</Suspense>
            </div>

            <Modal
                isOpen={isInviteDialogOpen}
                onClose={() => setIsInviteDialogOpen(false)}
            >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-textprimary mb-4">
                    Invite to Campaign
                </Dialog.Title>

                <p>Send this invite link to your party members.</p>
                <br />

                <span>{InviteLink}</span>

                <div className="mt-4 flex gap-2 justify-between">
                    <Button icon={DocumentDuplicateIcon} label={"Copy"} onClick={() => { navigator.clipboard.writeText(InviteLink); }} />
                    <Button label={"Close"} bgColor='bg-background' textColor="text-textprimary" border="border-border" onClick={() => setIsInviteDialogOpen(false)} />
                </div>
            </Modal>
        </>
    )

}
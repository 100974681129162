import { Fragment, useState } from 'react';
import { Disclosure, Menu, Switch, Dialog, Transition } from '@headlessui/react';
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../components/Atoms/Button";
import Input from "../components/Atoms/Input";
import Modal from '../components/Molecules/Modal';
import
    {
        CogIcon,
        CreditCardIcon,
        KeyIcon,
    } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

const subNavigation = [
    { name: 'Account', href: '/settings/account', icon: CogIcon, current: false },
    //{ name: 'Preferences', href: '/settings/preferences', icon: KeyIcon, current: false },
    // { name: 'Billing', href: 'settings/billing', icon: CreditCardIcon, current: false },
]

function classNames(...classes)
{
    return classes.filter(Boolean).join(' ')
}

export default function Profile()
{
    const { user, getAccessTokenSilently } = useAuth0();

    const [isEditEmailDialogOpen, setIsEditEmailDialogOpen] = useState(false);
    const [newEmailState, setNewEmailState] = useState({NewEmail: "", ConfirmEmail: "", NewEmailError: "", ConfirmEmailError: "" });


    const handleNewEmailStateInputChange = (event) =>
    {
        setNewEmailState({
            ...newEmailState,
            [event.target.name]: event.target.value,
        });
    };

    const handleUpdateEmail = async (event) =>
    {
        let _NewEmailError = "";
        let _ConfirmEmailError = "";
        let EmailRegex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/;

        let _NewEmailMatch = EmailRegex.test(newEmailState.NewEmail);
        let _ConfirmEmailMatch = EmailRegex.test(newEmailState.ConfirmEmail);

        if(!_NewEmailMatch)
            _NewEmailError = "Please enter a valid email"

        if(!_ConfirmEmailMatch)
            _ConfirmEmailError = "Please enter a valid email"

        if(newEmailState.NewEmail != newEmailState.ConfirmEmail)
            _ConfirmEmailError = "Emails must match";

        setNewEmailState({
            ...newEmailState,
            ["NewEmailError"]: _NewEmailError,
            ["ConfirmEmailError"]: _ConfirmEmailError
        });

        if(_NewEmailError != "" || _ConfirmEmailError != "")
            return;

        try
        {
            const token = await getAccessTokenSilently();

            const options = {
                method: 'POST',                
                url: "https://inkless.app/api/updateEmail",
                headers: { Authorization: `Bearer ${token}` },
                data: {
                    newEmail: newEmailState.NewEmail,
                }
            };

            axios(options).then((response) => {
                console.log("Updated Email: " + response.data.UpdatedEmail);
                user.email = response.data.UpdatedEmail;
            })
        }
        catch (error)
        {
            console.error(error);
        }

    }

    const handleResetPassword = async () =>
    {        
        var options = {
            method: 'POST',
            url: 'https://dev-xichdi6z.us.auth0.com/dbconnections/change_password',
            headers: {'content-type': 'application/json'},
            data: {
              client_id: 'bGGPRsCOuTGV5eCkkDoPb1aI1HdEiDvv',
              email: user.email,
              connection: 'Username-Password-Authentication'
            }
          };
          
          axios.request(options).then(function (response) {
            console.log(response.data);
          }).catch(function (error) {
            console.error(error);
          });
    }

    return (
        <div>
            <Disclosure as="div" className="relative overflow-hidden bg-background pb-32">
                {({ open }) => (
                    <>
                        {/* Fancy */}
                        <div
                            aria-hidden="true"
                            className={classNames(
                                open ? 'bottom-0' : 'inset-y-0',
                                'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
                            )}
                        >
                            <div className="absolute inset-0 flex">
                                <div className="h-full w-1/2 bg-primary"  />
                                <div className="h-full w-1/2 bg-primary" />
                            </div>
                            <div className="relative flex justify-center">
                                <svg
                                    className="flex-shrink-0"
                                    width={1750}
                                    height={308}
                                    viewBox="0 0 1750 308"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                </svg>
                            </div>
                        </div>

                        <header className="relative py-10">
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <h1 className="text-3xl font-bold tracking-tight text-white">Account</h1>
                            </div>
                        </header>
                    </>
                )}
            </Disclosure>

            <main className="relative -mt-32">
                <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
                    <div className="overflow-hidden rounded-lg bg-gray shadow">
                        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                            <aside className="py-6 lg:col-span-3">
                                <nav className="space-y-1">
                                    {subNavigation.map((item) => (
                                        <NavLink
                                            to={item.href}
                                            key={item.name}
                                            aria-current={({ isActive }) => isActive ? 'page' : undefined}
                                            className={({ isActive }) => classNames(isActive
                                                ? 'bg-accent border-background text-textaccent hover:bg-background hover:text-teal-700'
                                                : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                                                'group border-l-4 px-3 py-2 flex items-center text-sm font-bold')}
                                        >
                                            {({ isActive }) => (
                                                <>
                                                    <item.icon
                                                        className={classNames(
                                                            isActive
                                                                ? 'text-textaccent group-hover:text-accent'
                                                                : 'text-gray-400 group-hover:text-gray-500',
                                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    <span className="truncate">{item.name}</span>
                                                </>
                                            )}
                                        </NavLink>
                                    ))}
                                </nav>
                            </aside>

                            <form className="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
                                {/* Profile section */}
                                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                                    <h2 className="text-lg font-medium leading-6 text-gray-900">Security</h2>
                                    <div className="mt-6 grid grid-cols-12 gap-6">
                                        <div className="col-span-12 flex flex-row justify-between content-end">
                                            <div>
                                                <label htmlFor="first-name" className="block text-sm font-bold text-gray-700">
                                                    Email
                                                </label>
                                                <p className="mt-1 text-sm text-gray-900">
                                                    {user.email}
                                                </p>
                                            </div>
                                            <Button label={"Edit"} onClick={() => setIsEditEmailDialogOpen(true)} />
                                        </div>

                                        <div className="col-span-12 flex flex-row justify-between">
                                            <div>
                                                <label htmlFor="first-name" className="block text-sm font-bold text-gray-700">
                                                    Password
                                                </label>
                                                <p className="mt-1 text-sm text-gray-900">
                                                    We'll email you a link to reset your password
                                                </p>
                                            </div>
                                            <Button label={"Send Link"} onClick={handleResetPassword} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>

            <Modal
                isOpen={isEditEmailDialogOpen}
                onClose={() => setIsEditEmailDialogOpen(false)}
            >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-2">
                    Change Email
                </Dialog.Title>

                <Input
                    label={"New Email"}
                    value={newEmailState.NewEmail}
                    type="email"
                    name={"NewEmail"}
                    onChange={handleNewEmailStateInputChange}
                    error={newEmailState.NewEmailError}
                />

                <Input
                    label={"Confirm Email"}
                    value={newEmailState.ConfirmEmail}
                    type="email"
                    name={"ConfirmEmail"}
                    onChange={handleNewEmailStateInputChange}
                    error={newEmailState.ConfirmEmailError}
                />

                <div className="mt-4 flex gap-2 flex-row justify-end">
                    <Button
                        label={"Cancel"}
                        bgColor='bg-background'
                        textColor="text-textprimary" 
                        border="border-border"
                        onClick={() =>
                        {
                            setNewEmailState({ NewEmail: "", ConfirmEmail: "", NewEmailError: "", ConfirmEmailError: "" });
                            setIsEditEmailDialogOpen(false)
                        }} />
                    <Button label={"Update"} onClick={handleUpdateEmail} />
                </div>
            </Modal>
        </div>
    )
}

import React, { useState } from "react";
import { SunIcon, MoonIcon } from "@heroicons/react/24/outline";

function ThemeToggle() {
  
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.classList.toggle('dark');
  };

  return (
    <button
      type="button"
      onClick={toggleTheme}
      className="flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-primary focus:outline-none"
    >
      <span className="sr-only">Toggle theme</span>
      {theme === 'light' ? (
        <SunIcon className="h-6 w-6" aria-hidden="true" />
      ) : (
        <MoonIcon className="h-6 w-6" aria-hidden="true" />                          
      )}
    </button>
  );
}

export default ThemeToggle;
